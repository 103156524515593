import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useProposalBackupStore = defineStore('proposalBackup', () => {
  const proposalBackup = ref(null)
  
  function setProposalBackup(savePayload) {
    proposalBackup.value = savePayload;
  }

  return {proposalBackup, setProposalBackup}
})
