import Vue from "vue";

import { createPinia, PiniaVuePlugin } from 'pinia'
import configs from "@/configs";
import vuetify from "@/plugins/vuetify";
import { createPersistedStatePlugin } from 'pinia-plugin-persistedstate-2'
import VueRouter from "vue-router";
import SecureLS from "secure-ls";

import "@/plugins/axios";
import "@/plugins/vee";
import "@/plugins/vuetoastification";
import "@/registerServiceWorker";

import { filters, errorFunction } from "@/utils";
import setApiRoutes from "@/helpers/setApiRoutes";

import App from "@/App.vue";
import { VueMaskDirective } from "v-mask";
import VueGtag from "vue-gtag";
import * as VueGoogleMaps from "vue2-google-maps";

setApiRoutes(Vue, configs.url, configs.env);
Vue.config.productionTip = false;
Vue.prototype.$fnError = errorFunction;
Vue.prototype.$vuetify = vuetify;



Vue.use(PiniaVuePlugin)
const pinia = createPinia()

const ls = new SecureLS({ isCompression: false });
pinia.use(createPersistedStatePlugin({
  key: 'pinia',
  storage: {
    getItem: (key) => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: (key) => ls.remove(key)
  }
}))

Vue.use(VueRouter);

import router from "./router";


Vue.use(
  VueGtag,
  {
    config: { id: "G-SVPP3PSJ0V" },
  },
  router
);

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: "places",
  },
});

Vue.filter("dateFilter", filters.dateFilter);
Vue.filter("floatFilter", filters.floatFilter);
Vue.filter("cpfCnpjFilter", filters.cpfCnpjFilter);
Vue.filter("zipCodeFilter", filters.zipCodeFilter);

Vue.directive("mask", VueMaskDirective);


new Vue({
  router,
  pinia,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
