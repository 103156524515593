import { defineStore } from "pinia";
import { ref } from "vue";
import SecureLS from "secure-ls";
import configs from "../configs";

const ls = new SecureLS({ isCompression: false });

export const useSystemStore = defineStore("system", () => {
  const language = ref(configs.language)
  const clientId = ref(configs.id)
  const module = ref(configs.module)
  const system = ref(configs.system)

  function setLanguage(languageValue) {
    language.value = languageValue;
  }

  return {
    language,
    clientId,
    module,
    system,
    setLanguage
  };
}, {
  persist: true,
  storage: {
    getItem: (key) => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: (key) => ls.remove(key),
  }
},);
