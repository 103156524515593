
const configs = {
  id: 1,
  url: "bid",
  system: "atmo",
  module: "ZNAP_SECURITY",
  language: "pt",
  port: 80,
  theme: "znap.js",
  env: "homol",
};

export default configs;
