import { getCurrentInstance } from 'vue';
import {useToast} from '.'

export function useError() {
  const currentInstance = getCurrentInstance();

  const toast = useToast()
  const errorFunction = currentInstance.proxy.$fnError

  return {toast, errorFunction}
}
