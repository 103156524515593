import axios from "axios";
import Vue from "vue";
//import store from "@/store/index.js";
import setModule from "@/helpers/setModule.js";
import { useAuthStore } from "../stores/auth";
import { useSystemStore } from "../stores/system";

const instance = createInstance();

function createInstance() {
  return axios.create({
    headers: {
      "Content-Type": "application/json",
    },
  });
}

instance.interceptors.request.use(
  (request) => {
    const authStore = useAuthStore()
    const systemStore = useSystemStore()

    if (authStore.token) {
      request.headers.common.Authorization = `Bearer ${authStore.token}`;
    }

    let dataDefault = {
      language:
      systemStore.language === "pt_BR"
          ? "pt"
          : systemStore.language,
      clientId: systemStore.clientId,
      system: systemStore.system,
    };

    let module = setModule(request, systemStore.module);

    if (request.data instanceof FormData) {
      request.headers.common["Content-Type"] = "multipart/form-data";

      request.data.append("language", dataDefault.language);
      request.data.append("clientId", dataDefault.clientId);
      request.data.append("module", module);
      request.data.append("system", dataDefault.language);
    } else {
      request.data = {
        ...dataDefault,
        ...request.data,
        module,
      };
    }

    return request;
  },
  (err) => {
    return Promise.reject(err);
  }
);

Vue.prototype.$http = instance;

export default {
  getInstance: () => instance,
};
