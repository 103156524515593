export default function setModule(request, defaultModule) {
  // defaultModule => "ZNAP_SALES_PLANNING"

  let endpoint = request.url;
  let module = "";

  switch (true) {
    case endpoint.indexOf("events-summary") !== -1:
      module = "ZNAP_STOCK";
      break;
    case endpoint.indexOf("user/hash/login") !== -1:
      module = "ZNAP_SECURITY";
      break;
    case endpoint.indexOf("proposal-product-actual-costs-approval") !== -1:
      module = "ZNAP_STOCK";
        break;
    case endpoint.indexOf("technicians") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("user/menu") !== -1:
      module = request.data.module;
      break;
    case endpoint.indexOf("product-family") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("services-type") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("unit-measurement") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("proposal-type") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("clause-type") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("status-type") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("company") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("resource-type") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("item-category") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("omie-supplier") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("omie-product") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("omie-services") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("omie-customer") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("omie-customer-supplier-origin-company") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("omie-contact") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("omie-salesman") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("omie-buyer") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("omie-salesman-origin-company") !== -1:
      module = "ZNAP_MASTER_DATA";
      break;
    case endpoint.indexOf("approval-flow") !== -1:
      module = "ZNAP_APPROVAL_FLOW";
      break;
    case endpoint.indexOf("approval-flow-level") !== -1:
      module = "ZNAP_APPROVAL_FLOW";
      break;
    case endpoint.indexOf("proposal-approval") !== -1:
      module = "ZNAP_APPROVAL_FLOW";
      break;
    case endpoint.indexOf("purchase-requisition-approval") !== -1:
      module = "ZNAP_APPROVAL_FLOW";
      break;
    case endpoint.indexOf("proposal-product-actual-costs-closed") !== -1:
      module = "ZNAP_STOCK";
      break;
    case endpoint.indexOf("clause") !== -1:
      module = "ZNAP_PROPOSAL";
      break;
    case endpoint.indexOf("proposal") !== -1:
      module = "ZNAP_PROPOSAL";
      break;
    case endpoint.indexOf("proposal-clause") !== -1:
      module = "ZNAP_PROPOSAL";
      break;
    case endpoint.indexOf("proposal-service-costs") !== -1:
      module = "ZNAP_PROPOSAL";
      break;
    case endpoint.indexOf("proposal-product-costs") !== -1:
      module = "ZNAP_PROPOSAL";
      break;
    case endpoint.indexOf("stock-event-calendar") !== -1:
      module = "ZNAP_STOCK";
      break;
    case endpoint.indexOf("event") !== -1:
      module = "ZNAP_PROPOSAL";
      break;
    case endpoint.indexOf("omie-event-integration") !== -1:
      module = "ZNAP_PROPOSAL";
      break;
    case endpoint.indexOf("invoicing") !== -1:
      module = "ZNAP_PROPOSAL";
      break;
    case endpoint.indexOf("proposal-invoicing") !== -1:
      module = "ZNAP_PROPOSAL";
      break;
    case endpoint.indexOf("omie-invoicing-integration") !== -1:
      module = "ZNAP_PROPOSAL";
      break;
    case endpoint.indexOf("omie-purchase-requisition") !== -1:
      module = "ZNAP_PURCHASE";
      break;
    case endpoint.indexOf("omie-purchase-requisition-item") !== -1:
      module = "ZNAP_PURCHASE";
      break;
    case endpoint.indexOf("requisition-type") !== -1:
      module = "ZNAP_PURCHASE";
      break;
    case endpoint.indexOf("omie-service-requisition-item") !== -1:
      module = "ZNAP_PURCHASE";
      break;
    case endpoint.indexOf("purchase-report") !== -1:
      module = "ZNAP_PURCHASE";
    break;
    case endpoint.indexOf("purchase-omie-report") !== -1:
      module = "ZNAP_PURCHASE";
    break;
    case endpoint.indexOf("stock") !== -1:
      module = "ZNAP_STOCK";
      break;
    case endpoint.indexOf("bom-product-unit") !== -1:
      module = "ZNAP_STOCK";
      break;
    case endpoint.indexOf("bom-product") !== -1:
      module = "ZNAP_STOCK";
      break;
    case endpoint.indexOf("inventory-product-unit") !== -1:
      module = "ZNAP_STOCK";
      break;
    case endpoint.indexOf("bom-product-unit-tree-unit") !== -1:
      module = "ZNAP_STOCK";
      break;
    case endpoint.indexOf("checklist-return-history") !== -1:
      module = "ZNAP_STOCK";
      break;
    case endpoint.indexOf("occurrence-type") !== -1:
      module = "ZNAP_STOCK";
      break;
    case endpoint.indexOf("stock-movement-approval-history") !== -1:
      module = "ZNAP_STOCK";
      break;
    case endpoint.indexOf("checklist-shipment") !== -1:
      module = "ZNAP_STOCK";
      break;
    case endpoint.indexOf("checklist-return") !== -1:
      module = "ZNAP_STOCK";
      break;
    case endpoint.indexOf("occurrence") !== -1:
      module = "ZNAP_STOCK";
      break;
    case endpoint.indexOf("checklist-shipment-history") !== -1:
      module = "ZNAP_STOCK";
      break;
    default:
      module = defaultModule;
  }

  return module;
}
