export default function getApiLocalPort(path) {
    if (path === "user") {
        return 3000;
    } else if (path === "security") {
        return 3001;
    } else if (path === "master.data") {
        return 3002;
    } else if (path === "proposal") {
        return 3003;
    } else if (path === "purchase") {
        return 3004;
    } else if (path === "approval.flow") {
        return 3005;
    } else if (path === "integration.omie") {
        return 3006;
    } else if (path === "stock") {
        return 3007;
    } else if (path === "websocket") {
        return 9024;
    }
} 
