<template>
  <div>
    <v-dialog
      v-model="authStore.sessionExpiredDialog"
      max-width="900px"
      persistent
    >
      <v-dialog v-model="passwordDialog" max-width="500px" persistent>
        <v-card>
          <v-card-title class="headline pr-4">
            Renovar sessão

            <v-spacer></v-spacer>

            <v-btn class="pa-0" @click="passwordDialog = false" text icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="py-4">
            <v-text-field
              color="primary"
              label="Senha"
              prepend-icon="mdi-lock"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="showPassword = !showPassword"
              v-model="password"
            ></v-text-field>
          </v-card-text>

          <v-card-actions class="px-6 py-6">
            <v-spacer></v-spacer>

            <v-btn
              @click="passwordDialog = false"
              color="primary"
              class="mr-3"
              text
            >
              Cancelar
            </v-btn>

            <v-btn
              color="primary"
              @click="login()"
              :loading="loading"
              :disabled="!validPassword"
            >
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card style="position: relative">
        <v-card-title
          class="light--text"
          :style="`background: linear-gradient(90deg, ${vuetify.theme.themes.light.primary} 0%, ${vuetify.theme.themes.light.secondary} 100%)`"
          style="position: sticky; top: 0; width: 100%; z-index: 1000"
        >
          <span class="white--text headline">Sessão expirada</span>
        </v-card-title>
        <v-card-text class="py-4">
          <p class="ma-0">
            Escolha <strong>RENOVAR</strong> para continuar de onde parou ou
            <strong>FINALIZAR</strong> para encerrar a sessão atual e retonar
            para a tela de login.
          </p>
        </v-card-text>
        <div class="light" style="position: sticky; bottom: 0; width: 100%">
          <v-divider class="mx-4"></v-divider>
          <v-card-actions class="px-6 py-6">
            <v-spacer></v-spacer>
            <v-btn color="primary" text class="mr-4" @click="endSession()">
              Finalizar
            </v-btn>
            <v-btn color="primary" @click="passwordDialog = true">
              Renovar
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { useAuthStore } from "@/stores";
import { useError, useVuetify, useRouter } from "@/composables";

const authStore = useAuthStore();

const { errorFunction, toast } = useError();
const vuetify = useVuetify();
const router = useRouter();

const passwordDialog = ref(false);
const showPassword = ref(false);
const password = ref("");
const loading = ref(false);

const validPassword = computed(() => {
  const strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  return strongRegex.test(password.value);
});

function keyDownHandler(event) {
  if (
    (event.code === "Enter" || event.code === "NumpadEnter") &&
    password.value.length >= 6
  ) {
    login();
  }
}

onMounted(() => {
  window.addEventListener("keydown", keyDownHandler);
});

onUnmounted(() => {
  window.removeEventListener("keydown", keyDownHandler);
});

async function login() {
  loading.value = true;

  try {
    const res = await authStore.authenticate({
      email: authStore.user.email,
      password: password.value,
    });
    if (res) {
      toast.success("Sessão renovada com sucesso");
      authStore.setSessionExpiredDialog(false);
      loading.value = false;
      location.reload();
    } else {
      loading.value = false;
      errorFunction(res);
    }
  } catch (err) {
    errorFunction(err);
    loading.value = false;
  }
}

async function endSession() {
  try {
    await authStore.logout();
  } catch (error) {
    errorFunction(error);
  } finally {
    router.push("/login");
  }
}
</script>
