import { defineStore } from "pinia";
import { ref } from "vue";

export const useStockStore = defineStore("stock", () => {
  const isFlagged = ref(0);
  const sendApprovalEmail = ref(false);

  function setFlaggedStatus(status) {
    isFlagged.value = status;
  }

  function setSendApprovalEmail(value) {
    sendApprovalEmail.value = value;
  }

  return {
    isFlagged,
    sendApprovalEmail,
    setFlaggedStatus,
    setSendApprovalEmail,
  };
});
