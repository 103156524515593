import { defineStore } from "pinia";
import { ref } from "vue";

import configs from "@/configs";

export const useThemeStore = defineStore("theme", () => {
  const theme = ref(configs.theme);
  const logo = ref({
    home: `${configs.theme.split(".")[0]}-home.png`,
    appBar: `${configs.theme.split(".")[0]}-app-bar.png`,
  });

  return { theme, logo };
});
