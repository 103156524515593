import { defineStore } from "pinia";
import { ref } from "vue";

export const useChecklistStatusStore = defineStore("checklistStatus", () => {
  const isShipmentStore = ref(false);
  const isReturnStore = ref(false);
  const proposalId = ref(null);

  function setShipmentStatus(status) {
    isShipmentStore.value = status;
  }

  function setReturnStatus(status) {
    isReturnStore.value = status;
  }

  function setProposalId(id) {
    proposalId.value = id;
  }

  return {
    isShipmentStore,
    isReturnStore,
    proposalId,
    setShipmentStatus,
    setReturnStatus,
    setProposalId,
  };
});
